
//本地引入
import DTCls from "@/utils/datetime";
import { homePage } from "@/api/request/base";

//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Dashboard",
})

//组件函数
export default class extends Vue {
  //显示等待
  private loading = false;

  //定义变量
  private page: any = {
    //用户
    home_user: {
      man_num: 0,
      diamond: 0,
      hd_coin: 0,
      reg_num: 1,
      woman_num: 0,
      reg_total: 0,
      active_num: 0,
      reg_man_num: 1,
      reg_woman_num: 0,
      blogger_total: 0,
      certified_num: 0,
      wait_integral: 0,
      certified_total: 0,
      active_user_num: 0,
      certified_man_num: 0,
      active_blogger_num: 0,
      certified_woman_num: 0,
      blogger_certified_num: 0,
      wait_withdraw_integral: 0,
      withdrawal_integral: "0.00 / 0.00",
    },

    //充值
    home_recharge: {
      recharge: "0.00 / 0",
      month_card_buy_user: 0,
      first_recharge: "0.00 / 0",
    },

    //西澳西
    home_rtc: {
      chat_total: 0,
      video_duration: 0,
      voice_duration: 0,
      speed_match_num: 0,
      video_voice_total: 0,
    },

    //聊天
    home_prop: {
      chat_hd: "",
      square_hd: "",
      send_chat_prop: 0,
      send_video_prop: 0,
      product_hd_coin: 3,
      consume_hd_coin: "",
      product_integral: 0,
      consume_chat_prop: 0,
      consume_video_prop: 0,
      month_back_diamond: 0,
      send_gift: "0.00 / 0",
      consume_diamond: "0 / 0",
    },

    //广场
    home_square: {
      square_back: "0 / 0",
      square_like: "0 / 0",
      square_new_push: "0 / 0",
    },
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //时间参数
  private params: { start_time?: any; end_time?: any; time?: any } = {
    start_time: "",
    end_time: "",
    time: [],
  };

  //创建
  created() {
    //初始化时间
    this.initTime();

    //时间设置
    this.params.end_time += " 23:59:59";
    this.params.start_time += " 00:00:00";

    //获取页面
    this.getPage();
  }

  //获取页面
  private async getPage() {
    //显示等待
    this.loading = true;

    //获取数据
    const res = await homePage(this.params);

    //数据赋值
    this.page = res.data;

    //隐藏等待
    this.loading = false;
  }

  //初始化时间
  private initTime(): void {
    //获取当天时间
    const currentDay = DTCls.getCurDateTimeYMD();

    //数据赋值
    this.params.end_time = `${currentDay}`;
    this.params.start_time = `${currentDay}`;
    this.params.time = [this.params.start_time, this.params.end_time];
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //获取页面
    this.getPage();
  }

  //处理筛选
  private handleFilter(): void {
    //数据赋值
    this.params.end_time = `${this.params.time[1]} 23:59:59`;
    this.params.start_time = `${this.params.time[0]} 00:00:00`;

    //获取页面
    this.getPage();
  }
}
